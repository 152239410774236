// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../../../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers/[...].jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-services-v-2-jsx": () => import("./../../../src/pages/services-v2.jsx" /* webpackChunkName: "component---src-pages-services-v-2-jsx" */),
  "component---src-pages-storybook-jsx": () => import("./../../../src/pages/storybook.jsx" /* webpackChunkName: "component---src-pages-storybook-jsx" */),
  "component---src-pages-work-jsx": () => import("./../../../src/pages/work.jsx" /* webpackChunkName: "component---src-pages-work-jsx" */),
  "component---src-pages-work-old-jsx": () => import("./../../../src/pages/work-old.jsx" /* webpackChunkName: "component---src-pages-work-old-jsx" */),
  "component---src-templates-aws-all-aws-services-index-jsx": () => import("./../../../src/templates/Aws/AllAwsServices/index.jsx" /* webpackChunkName: "component---src-templates-aws-all-aws-services-index-jsx" */),
  "component---src-templates-aws-index-jsx": () => import("./../../../src/templates/Aws/index.jsx" /* webpackChunkName: "component---src-templates-aws-index-jsx" */),
  "component---src-templates-blog-list-index-jsx": () => import("./../../../src/templates/BlogList/index.jsx" /* webpackChunkName: "component---src-templates-blog-list-index-jsx" */),
  "component---src-templates-blog-page-index-jsx": () => import("./../../../src/templates/BlogPage/index.jsx" /* webpackChunkName: "component---src-templates-blog-page-index-jsx" */),
  "component---src-templates-casestudy-index-jsx": () => import("./../../../src/templates/Casestudy/index.jsx" /* webpackChunkName: "component---src-templates-casestudy-index-jsx" */),
  "component---src-templates-cloud-front-solutions-index-jsx": () => import("./../../../src/templates/CloudFrontSolutions/index.jsx" /* webpackChunkName: "component---src-templates-cloud-front-solutions-index-jsx" */),
  "component---src-templates-data-ai-index-jsx": () => import("./../../../src/templates/DataAI/index.jsx" /* webpackChunkName: "component---src-templates-data-ai-index-jsx" */),
  "component---src-templates-event-page-data-strategy-gen-ai-business-growth-index-jsx": () => import("./../../../src/templates/EventPage/DataStrategyGenAIBusinessGrowth/index.jsx" /* webpackChunkName: "component---src-templates-event-page-data-strategy-gen-ai-business-growth-index-jsx" */),
  "component---src-templates-event-page-index-jsx": () => import("./../../../src/templates/EventPage/index.jsx" /* webpackChunkName: "component---src-templates-event-page-index-jsx" */),
  "component---src-templates-event-page-industry-leaders-index-jsx": () => import("./../../../src/templates/EventPage/IndustryLeaders/index.jsx" /* webpackChunkName: "component---src-templates-event-page-industry-leaders-index-jsx" */),
  "component---src-templates-event-page-it-industry-leaders-index-jsx": () => import("./../../../src/templates/EventPage/ITIndustryLeaders/index.jsx" /* webpackChunkName: "component---src-templates-event-page-it-industry-leaders-index-jsx" */),
  "component---src-templates-event-page-marketing-industry-leaders-index-jsx": () => import("./../../../src/templates/EventPage/MarketingIndustryLeaders/index.jsx" /* webpackChunkName: "component---src-templates-event-page-marketing-industry-leaders-index-jsx" */),
  "component---src-templates-event-page-media-industry-leaders-index-jsx": () => import("./../../../src/templates/EventPage/MediaIndustryLeaders/index.jsx" /* webpackChunkName: "component---src-templates-event-page-media-industry-leaders-index-jsx" */),
  "component---src-templates-gcp-index-jsx": () => import("./../../../src/templates/Gcp/index.jsx" /* webpackChunkName: "component---src-templates-gcp-index-jsx" */),
  "component---src-templates-gen-ai-index-jsx": () => import("./../../../src/templates/GenAI/index.jsx" /* webpackChunkName: "component---src-templates-gen-ai-index-jsx" */),
  "component---src-templates-redirect-page-index-jsx": () => import("./../../../src/templates/RedirectPage/index.jsx" /* webpackChunkName: "component---src-templates-redirect-page-index-jsx" */),
  "component---src-templates-salesforce-index-jsx": () => import("./../../../src/templates/Salesforce/index.jsx" /* webpackChunkName: "component---src-templates-salesforce-index-jsx" */),
  "component---src-templates-service-details-page-index-jsx": () => import("./../../../src/templates/ServiceDetailsPage/index.jsx" /* webpackChunkName: "component---src-templates-service-details-page-index-jsx" */),
  "component---src-templates-snowflake-index-jsx": () => import("./../../../src/templates/Snowflake/index.jsx" /* webpackChunkName: "component---src-templates-snowflake-index-jsx" */)
}

